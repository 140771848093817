<template>
  <div
    class="response-card rounded-20 py-md-34 py-15 mb-24 d-flex flex-column flex-md-row justify-content-between align-items-center"
    :class="(isActive ? 'bg-apply shadow-1' : 'bg-white shadow-2') + (activeTab === 2 ? ' flex-md-wrap' : '')"
  >
    <div
      class="response-card-wrap d-flex flex-wrap align-items-center gap-15 px-15"
      :class="{'col-md-6' : activeTab === 2}"
    >
      <div
        class="align-items-center justify-content-end w-100 w-md-auto"
        :class="showViewStatus ? 'd-flex d-md-none' : 'd-none'"
      >
        <time class="response-card__time text-end text-md-center text-gray d-none">
          <span class="d-none d-md-block">
            {{ date.toLocaleString(['ru-RU', 'en-US'], {day: '2-digit', month: '2-digit', year: '2-digit'}) }}
          </span>
          <span class="d-inline d-md-none">
            {{ date.toLocaleString(['ru-RU', 'en-US'], {day: '2-digit', month: 'short'}) }}
          </span>
          {{ date.toLocaleString(['ru-RU', 'en-US'], {hour: '2-digit', minute: '2-digit'}) }}
        </time>
        <button
          class="response-card-info__view d-block d-md-none"
          :class="!viewStatus ? null : 'response-card-info__view_filled'"
          style="height: 15px; width: 15px;"
          :title="'Отметить как ' + (viewStatus ? 'просмотренное' : 'непросмотренное')"
          @click.stop="$emit('changeViewStatus')"
        />
      </div>
      <div
        style="height: 80px; width: 80px;"
      >
        <img
          v-if="img !== null"
          class="response-card-wrap__img rounded-20 h-100 w-100"
          style="object-fit: contain"
          :src="img"
          :alt="companyName === undefined ? title : companyName"
          height="80"
          width="80"
        >
      </div>
      <div class="response-card-info d-flex flex-column justify-content-between">
        <div class="d-flex align-items-center">
          <button
            v-if="showComment"
            class="btn btn-accent rounded-circle p-0 me-10 d-none"
            title="Комментарий"
            style="height: 25px; width: 25px;"
          >
            c
          </button>
          <div class="d-flex flex-column align-items-start">
            <button
              v-if="false"
              class="btn btn-link text-accent p-0"
              :class="{'invisible' : !isShow, 'd-none' : activeTab === 3 || isVacancy}"
              title="Взять в работу"
              @click.stop="setInterviewer"
            >
              Взять в работу
            </button>
            <span class="response-card-info__title h4 fw-bold m-0">{{ title }}</span>
          </div>
        </div>
        <template v-if="salary !== undefined">
          <span
            v-if="salary.min === null && salary.max === null"
            class="response-card-info__salary"
          >
            не указана
          </span>
          <span
            v-else
            class="response-card-info__salary"
          >
            {{ salary.min === null ? '' : 'от ' + salary.min.toLocaleString() }}
            {{ salary.max === null ? '' : 'до ' + salary.max.toLocaleString() }}
            руб/мес
          </span>
        </template>
        <span class="response-card-info__name">{{ companyName }}</span>
      </div>
    </div>
    <div
      v-if="startDate !== undefined"
      class="pt-md-15 pb-md-0 py-10 bg-md-transparent bg-alice-blue px-20 w-100 mt-md-0 mt-20"
      :class="{'order-md-2' : activeTab === 2}"
    >
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-lg-block d-flex flex-column">
          <span class="lh-1 mb-lg-0 mb-5">{{ startDate.toLocaleDateString(['ru-RU', 'en-US'], {day: '2-digit', month: 'long'}) }}</span>
          <span class="d-lg-inline-block d-none">,</span>
          <span class="lh-1 ms-lg-5 ms-0">{{ startDate.toLocaleDateString(['ru-RU', 'en-US'], {weekday: 'long'}) }}</span>
        </div>
        <span style="font-size: 35px;">{{ startTime.toLocaleTimeString(['ru-RU', 'en-US'], { hour: '2-digit', minute: '2-digit' }) }}</span>
        <span>{{ platform === 1 ? 'Онлайн' : 'Офис' }}</span>
        <span
          class="d-md-block d-none custom-size"
          :class="{'text-center' : platform === 1}"
        >
          {{ platform === 1 ? 'Созвон через ' : null }}
          <span :class="{'fw-bold' : platform === 1}">{{ location }}</span>
        </span>
        <div
          v-if="!isVacancy"
          style="height: 40px; width: 40px;"
        >
          <AppButton
            title="Редактировать"
            :variant="BtnVariant.Tertiary"
            is-icon
            @click.stop="emits('edit')"
          >
            <svg class="h-100 w-100">
              <use xlink:href="/public/icons/auth-sprite.svg#stylus" />
            </svg>
          </AppButton>
        </div>
      </div>
      <span class="d-block d-md-none">
        {{ platform === 1 ? 'Созвон через ' : null }}
        <span :class="{'fw-bold' : platform === 1}">{{ location }}</span>
      </span>
      <p class="text-gray fs-14 m-0 text-break mt-5">
        {{ description }}
      </p>
    </div>
    <div
      class="d-flex px-15 flex-wrap flex-md-nowrap justify-content-between align-items-center w-100 w-lg-auto w-md-40 mt-20 mt-md-0"
      :class="{'col-md-6' : activeTab === 2}"
    >
      <div
        v-if="activeTab === 2"
        class="col-md-12 col-6"
      >
        <AppButton
          class="lh-1"
          :class="{ 'bg-transparent text-gray fs-16' : !isOnline}"
          content-style="white-space: normal;"
          :style="!isOnline ? 'pointer-events: none' : null"
          @click.stop="call"
        >
          {{ status }}
        </AppButton>
      </div>
      <div
        class="d-flex justify-content-between align-items-center"
        :class="{'col-md-auto col-12' : activeTab !== 2}"
      >
        <div
          class="response-card__status text-gray"
          :class="( (!isAnsweredInterview && isVacancy && activeTab === 1) || activeTab === 2 ? 'd-none' : 'd-block')"
          :style="activeTab === 2 ? 'font-size: 15px' : null"
        >
          {{ status }}
        </div>
        <AppButton
          :class="{'d-none' : isAnsweredInterview || activeTab !== 1 || !isVacancy}"
          content-style="white-space: normal;"
          :to="interviewUrl"
        >
          {{ status }}
        </AppButton>
        <AppButton
          class="d-md-none"
          :variant="BtnVariant.Secondary"
          @click.stop="$emit('openClick')"
        >
          Смотреть
        </AppButton>
      </div>
      <button
        v-if="showViewStatus"
        class="response-card-info__view d-none d-md-block ms-10"
        :class="!viewStatus ? null : 'response-card-info__view_filled'"
        :title="'Отметить как ' + (viewStatus ? 'просмотренное' : 'непросмотренное')"
        @click.stop="$emit('changeViewStatus')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Salary } from "~/entities/salary";
import { BtnVariant } from "~/composables/enums/BtnVariant";
import type { PropType } from "vue";

const { $toast } = useNuxtApp();

const props = defineProps({
  id: { type: String, default: null },
  title: { type: String, required: true },
  companyId: { type: String, default: null },
  employeeId: { type: String, default: null },
  isVacancy: {type: Boolean, default: false},
  isSetInterviewer: { type: Boolean, default: false },
  companyName: { type: String, default: null },
  salary: { type: Object as PropType<Salary>, default: () => {} },
  img: { type: String, default: null },
  date: { type: Date, required: true },
  status: { type: String, default: '' },
  startTime: { type: Date, default: undefined },
  startDate: { type: Date, default: undefined },
  platform: { type: Number, default: null },
  location: { type: String, default: null },
  description: { type: String, default: null },
  isAnsweredInterview: { type: Boolean, default: false },
  interviewUrl: { type: String, default: null },
  isOnline: { type: Boolean, default: false },
  activeTab: { type: Number, default: 0 },
  /**
   * Тип собеседования
   * 0 - онлайн
   * 1 - оффлайн
   */
  colloquy: { type: Number, default: 0 },
  showViewStatus: { type: Boolean, default: false },
  viewStatus: { type: Boolean },
  showComment: { type: Boolean, default: false },
  isActive: { type: Boolean, default: false },
  isUngroupColloquies: { type: Boolean, default: false },
});
const emits = defineEmits(['openClick', 'changeViewStatus', 'call', 'edit']);

//-----STATE-----\\
const isShow = ref<boolean>(props.isSetInterviewer);

//-----METHODS-----\\
function call() {
  if (props.isOnline) {
    emits('call');
  }
}
async function setInterviewer() {
  await mainFetch(`/responses/${props.id}`, {
    method: 'PUT',
    body: {
      isSetInterviewer: true
    }
  }).then((resp) => {
    resp = resp.data.value??resp.error.value?.data;
    if (resp.error.code !== 200) {
      $toast.error(resp.error.clientMessage ?? resp.error.message);
      return;
    }

    isShow.value = false;
    $toast.success('Вы назначены на отклик');
  });
}
</script>

<style scoped lang="scss">
.custom-size {
  width: 350px;

  @media (max-width: 1200px) {
    width: 250px;
  }

  @media (max-width: 992px) {
    width: 200px;
  }
}
.response-card {
  &-info {
    &__view {
      height: 15px;
      width: 15px;
      border-radius: 50%;
      border: 2px solid var(--bs-primary);
      background-color: transparent;
      padding: 0;

      &_filled {
        background-color: var(--bs-primary);
      }
    }
  }

  &__time {
    width: 65px;
  }

  &__status {
    color: var(--bs-primary);
  }

  &__status-detail {
    font-size: 16px;
    line-height: 1;
  }
}
@media (max-width: 767px) {
  .response-card {
    &-wrap {
      width: 100%;

      &__img {
        height: 65px;
        width: 65px;
      }
    }

    &__start-time {
      order: 0;
    }

    &__time {
      width: auto;
      font-size: 12px;
    }

    &-info {
      flex: 1;

      &__title {
        font-size: 22px;
      }
      &__salary, &__name {
        font-size: 15px;
      }
    }

    &__status {
      line-height: 1;
      color: var(--bs-gray);
    }
  }
}
</style>
