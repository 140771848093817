<template>
  <div
    v-if="isCompany"
    class="d-flex mb-15"
  >
    <AppButton
      class="w-50 me-15"
      :variant="BtnVariant.White"
      :active="!isClickPast"
      @click="changeTab"
    >
      Назначенные
    </AppButton>
    <AppButton
      class="w-50"
      :variant="BtnVariant.White"
      :active="isClickPast"
      @click="changeTab"
    >
      Прошедшие
    </AppButton>
  </div>
  <div
    v-show="isClickPast && isCompany"
    class="text-gray text-center col-12 mb-15"
  >
    Прошедшие собесы хранятся 2 недели. Далее отклик переносится в архив.
  </div>
  <CheckBox
    v-if="isCompany"
    class="mb-15"
    :is-checked="isGroup"
    @change="ungroupingColloquies"
  >
    Группировать по датам
  </CheckBox>
  <swiper
    v-show="isViewCalendar"
    class="date-swiper mb-34"
    slides-per-view="auto"
    :space-between="24"
    :slides-per-group="1"
  >
    <swiper-slide
      v-for="i in 14"
      style="padding-top: calc(1.5em + 10px);"
      class="slide-item"
    >
      <button
        class="date-swiper-item position-relative btn btn-calendar shadow-none rounded-20 fw-bold px-15 py-24 p-md-24 w-100"
        :class="i === activeDate ? 'active' : ''"
        :set="newDate = getCalendarDate(i)"
        @click="changeDate(i)"
      >
        <span
          v-if="i === 1 || (getCurrentDate.getMonth() !== newDate.getMonth() && newDate.getDate() === 1)"
          class="position-absolute text-dark"
          style="top: calc(-1.5em - 10px); left: 0;"
        >
          {{ newDate.toLocaleString(['ru-RU', 'en-US'], { month: 'long' }) }}
        </span>
        <span
          v-if="isBusyDay(i)"
          class="date-swiper-item__status d-block position-absolute rounded-circle bg-primary"
        />
        {{ newDate.getDate() }}<br>
        <span>
          {{ newDate.toLocaleString(['ru-RU', 'en-US'], { weekday: 'short' }) }}
        </span>
      </button>
    </swiper-slide>
  </swiper>
</template>

<script setup lang="ts">
import CheckBox from "~/components/inputs/checkBox.vue";
import { BtnVariant } from "~/composables/enums/BtnVariant";
import type { PropType } from "vue";

const props = defineProps({
  busyDays: { type: Array as PropType<Date[]>, default: () => [] },
  isCompany: { type: Boolean, default: false },
  activeIndex: { type: Number, default: null },
});

const emits = defineEmits(['activeDate', 'ungroupingColloquies', 'changeTab']);

//-----VARIABLES-----\\
let currentDate = new Date();
currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

//-----STATE-----\\
const activeDate = ref<number>(1);
const isClickPast = ref<boolean>(false);
const busyDaysPrivate = ref<number[]>([]);
const isGroup = ref<boolean>(true);
const isChangeTab = ref<boolean>(false);
const isViewCalendar = ref<boolean>(true);

//-----COMPUTED-----\\
watch(() => props.busyDays, (newBusyDays: number[]) => {
  buildBusyDays(newBusyDays);
  if (isChangeTab.value && isGroup.value) {
    changeDate(Number(Object.keys(busyDaysPrivate.value)[0]) + 1);
  } else {
    activeDate.value = props.activeIndex;
  }
});

const getCurrentDate = computed(() => currentDate);

//-----ASYNC-----\\
buildBusyDays(props.busyDays);
changeDate(Number(Object.keys(busyDaysPrivate.value)[0]) + 1);

//-----METHODS-----\\
function getCalendarDate(index: number) {
  return new Date(new Date().setDate(currentDate.getDate() + (isClickPast.value ? -index : index - 1) ));
}
function buildBusyDays(days: number[]) {
  busyDaysPrivate.value = [];
  days.map(value => {
    const datetime = new Date(value * 1000);
    const date = new Date(datetime.getFullYear(), datetime.getMonth(), datetime.getDate());

    const i = Math.round(Math.abs(date.getTime() - currentDate.getTime()) / 86400000) - (isClickPast.value ? 1 : 0);
    busyDaysPrivate.value[i] = date;
  });
}
function isBusyDay(i: number): boolean {
  return busyDaysPrivate.value[i-1] !== undefined;
}
function changeTab() {
  isClickPast.value = !isClickPast.value;
  isChangeTab.value = true;
  emits('changeTab', {isPast: isClickPast.value, isGroup: isGroup.value});
}
function changeDate(i: number) {
  isChangeTab.value = false;
  activeDate.value = i;
  emits('activeDate', {busyDate: busyDaysPrivate.value[i - 1], activeIndex: i});
}
function ungroupingColloquies() {
  isGroup.value = !isGroup.value;
  if (isGroup.value) {
    isViewCalendar.value = true;
    changeDate(Number(Object.keys(busyDaysPrivate.value)[0]) + 1);
  } else {
    isViewCalendar.value = false;
    emits('ungroupingColloquies', isGroup.value);
  }
}
</script>

<style lang="scss">
.date-swiper {
  &-item{
    &__status {
      top: 24px;
      right: 34px;
      height: 10px;
      width: 10px;
    }
  }
}
.slide-item {
  width: 120px;
  @media (max-width: 767px) {
    width: auto;
  }
}

@media (max-width: 767px) {
  .date-swiper {
    margin: 0 -15px;
    padding-left: 15px;

    &-item{
      line-height: 1;

      &__status {
        right: calc(50% - 5px);
        top: 6px;
      }
    }
  }
}
</style>
